<template>
	<div>
		<a-spin :spinning="loading">
			<div class="bg-w pd30" style="min-height: 800px;">
				<div class="flex space">
					<div class="ft20 ftw500 cl-main">编辑应用</div>
					<a-button @click="back()">返回</a-button>
				</div>
			
				<div class="mt20">
					<a-form-model ref="ruleForm" :model="form" :label-col="{ span:  3}" :wrapper-col="{ span: 21}">
						<a-form-model-item label="应用名称" required help="10字以内">
							<a-input style="width: 320px;" v-model="form.name" placeholder="请输入应用名称" />
						</a-form-model-item>
						
						<a-form-model-item label="应用标识" required>
							<a-input style="width: 320px;" v-model="form.iden" placeholder="请输入应用标识" 
							:change="check()"/>
						</a-form-model-item>
						
						<a-form-model-item label="应用图标" required>
							<upload-img v-model="form.logo"></upload-img>
						</a-form-model-item>
			
						<a-form-model-item label="应用类型" required>
							<a-select v-model="form.type" style="width: 120px;">
								<a-select-option :value="item.id" v-for="(item,index) in app_type" :key="index">
									{{item.name}}
								</a-select-option>
							</a-select>
						</a-form-model-item>
						
						
			
						<a-form-model-item label="应用排序" required help="数字越大越靠前">
							<a-input style="width: 160px;" v-model="form.sort" placeholder="请输入排序号" />
						</a-form-model-item>
			
						<a-form-model-item label="设为推荐" required>
							<a-radio-group name="radioGroup" v-model="form.is_tui">
								<a-radio :value="1">
									是
								</a-radio>
								<a-radio :value="0">
									否
								</a-radio>
							</a-radio-group>
						</a-form-model-item>
			
						<a-form-model-item label="开发白名单" required>
							<div class="edit-app-white-list-action ml10" @click="chooseAct()">选择</div>
							<div class="mt12 ml10">
								<div class="flex wrap">
									<div class="edit-app-white-list-item" v-for="(item,index) in white_list_data">
										<div class="flex alcenter">
											<img src="../../assets/image/logo48@2x.png" class="edit-app-white-list-item-face"/>
											<div class="ft12 ftw400 cl-main ml8">
												<div class="text-over4">{{item.username}}</div>
												<div class="text-over4 mt4">{{item.mobile}}</div>
											</div>
										</div>
			
										<div class="edit-app-white-list-item-del" @click="delWhiteList(index)">
											<i class="iconfont iconbtn_close cl-w ft12" />
										</div>
									</div>
								</div>
							</div>
						</a-form-model-item>
			
						<a-form-model-item label="生产地址" required>
							<a-input style="width: 320px;" v-model="form.production_address" placeholder="请输入" />
						</a-form-model-item>
						<a-form-model-item label="开发地址" required>
							<a-input style="width: 320px;" v-model="form.development_address" placeholder="请输入" />
						</a-form-model-item>
						<a-form-model-item label="试用天数" required>
							<div class="flex alcenter">
								<a-input-number style="width: 160px; text-align: right;" :formatter="value => `${value}天`" v-model="form.trial_days" :precision="0" :min="0" />
							</div>
						</a-form-model-item>
			
						<a-form-model-item label="套餐规格" required>
							<div class="edit-app-sku">
								<div style="width: 33%;" class="flex space ft14 ftw500 cl-main pr10 pl20">
									<div>时长</div>
								</div>
								<div class="pl10" style="width: 33%;">原价</div>
								<div class="pl10" style="width: 33%;">售价</div>
							</div>
			
							<div class="edit-app-sku-item" v-for="(item,index) in form.sku">
								<div style="position: relative;" class="flex alcenter">
									<a-input-number style="width:210px;" v-model="form.sku[index].num" :precision="0" :min="0" >
										
									</a-input-number>
									<a-select v-model="form.sku[index].unit" style="width: 70px" class="edit-app-sku-item-unit-addon">
										<a-select-option :value="1">
											天
										</a-select-option>
										<a-select-option :value="2">
											月
										</a-select-option>
										<a-select-option :value="3">
											年
										</a-select-option>
									</a-select>
								</div>
			
								<div style="position: relative;margin-left: 20px;">
									<a-input-number style="width:210px;" v-model="form.sku[index].base_price" :precision="2" :min="0" />
									<div class="edit-app-sku-item-addon">元</div>
								</div>
								
								<div style="position: relative;margin-left: 20px;">
									<a-input-number style="width:210px;" v-model="form.sku[index].price" :precision="2" :min="0" />
									<div class="edit-app-sku-item-addon">元</div>
								</div>
			
								<i class="iconfont iconbtn_close ft16 clickAct ml20" @click="delItem(index)" />
							</div>
			
							<div class="flex end mt10" style="width: 750px;">
								<a-button icon="plus" type="primary" ghost @click="addItem()">新建</a-button>
							</div>
						</a-form-model-item>
			
						<a-form-model-item label="适用场景" required class="mt20">
							<a-input type="textarea" style="width: 375px;" v-model="form.scene"></a-input>
						</a-form-model-item>
			
			
						<a-form-model-item :wrapper-col="{ span: 14, offset: 3 }">
							<a-button type="primary" :loading="confirmLoading" @click="onSubmit()">
								保存应用
							</a-button>
						</a-form-model-item>
					</a-form-model>
				</div>
			
				<div v-if="chooseMerchantLoading">
					<choose-merchant v-model="form.white_list"  :visible="chooseMerchantLoading" @cancel="cancelChooseMerchant" @ok="okChooseMerchant"></choose-merchant>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import chooseMerchant from './components/edit/modal/chooseMerchant.vue';
	import uploadImg from '../../components/upload/upload.vue';
	export default {
		components: {
			chooseMerchant,
			uploadImg,
		},
		data() {
			return {
				loading:false,
				confirmLoading:false,
				chooseMerchantLoading: false,
				app_id:0,
				app_type:[],
				white_list_data:[],
				form: {
					name: '',
					iden:'',
					logo: '',
					type: 1,
					sort: 999,
					is_tui: 1,
					white_list:[],
					production_address: '',
					development_address: '',
					trial_days: 0,
					sku: [],
					scene: '',
				}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.app_id=0;
			} else {
				this.app_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			getTitle(){
				if(this.app_id==0){
					return '添加应用';
				}else{
					return '编辑应用';
				}
			}
		},
		methods: {
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/showAppliance',{
					app_id:this.app_id
				}).then(res=>{
					this.loading=false;
					this.app_type=res.app_type;
					if(res.detail){
						this.form=res.detail;
						this.getAccountData();
					}
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);//返回上一层
				})
			},
			
			getAccountData(){
				this.$http.api('platform/admin/applianceGetAccountData',{
					white_list:this.form.white_list,
				}).then(res=>{
					this.white_list_data=res.data;
				}).catch(res=>{
					console.log(res);
				})
			},
			
			onSubmit(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('platform/admin/saveAppliance',{
					app_id:this.app_id,
					name:this.form.name,
					iden:this.form.iden,
					logo:this.form.logo,
					type:this.form.type,
					sort:this.form.sort,
					is_tui:this.form.is_tui,
					production_address:this.form.production_address,
					development_address:this.form.development_address,
					white_list_data:JSON.stringify(this.white_list_data),
					trial_days:this.form.trial_days,
					sku:JSON.stringify(this.form.sku),
					scene:this.form.scene,
				}).then(res=>{
					this.confirmLoading=false;
					this.$message.success('保存成功',1,()=>{
						this.back();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
			delWhiteList(index){
				this.form.white_list.splice(this.form.white_list.indexOf(this.white_list_data[index].account_id),1);
				this.white_list_data.splice(index,1);
			},
			
			back() {
				this.$router.go(-1); //返回上一层
			},
			
			check(){
				this.form.iden=this.form.iden.replace(/[^a-zA-Z]/g, '');
			},

			addItem() {
				this.form.sku.push({
					num: 0,
					unit: 1,
					base_price:0,
					price: 0,
				});
			},
			delItem(index) {
				this.form.sku.splice(index, 1);
			},

			chooseAct() {
				this.chooseMerchantLoading = true;
			},
			cancelChooseMerchant() {
				this.chooseMerchantLoading = false;
			},
			okChooseMerchant() {
				this.chooseMerchantLoading = false;
				this.getAccountData();
				console.log(this.white_list_data);
			},
		}
	}
</script>

<style>
	.edit-app-white-list-action {
		width: 76px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #4772FF;

		font-size: 14px;
		font-weight: bold;
		color: #4772FF;

		line-height: 40px;
		text-align: center;
		cursor: pointer;
	}

	.edit-app-white-list-item {
		width: 160px;
		height: 52px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		padding: 10px;
		line-height: 12px;

		position: relative;
		display: flex;
		margin-right: 20px;

	}

	.edit-app-white-list-item img {
		width: 32px;
		height: 32px;
		border-radius: 16px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
	}

	.edit-app-white-list-item-del {
		position: absolute;
		right: -14px;
		top: -14px;

		width: 28px;
		height: 28px;
		background: rgba(0, 0, 0, 0.4);
		border-radius: 48px;
		line-height: 28px;
		text-align: center;
		cursor: pointer;
	}

	.edit-app-sku {
		width: 740px;
		height: 40px;
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		display: flex;
	}


	.edit-app-sku .ant-select-arrow {
		color: #656A75;
	}

	.edit-app-sku .ant-select-selection {
		background-color: #F9F9F9;
		border: none;
		box-shadow: none;
	}

	.edit-app-sku-item {
		width: 740px;
		display: flex;
		border-bottom: 1px solid #EBEDF5;
		padding: 10px 20px 10px 20px;
	}

	.edit-app-sku-item-addon {
		position: absolute;
		right: 26px;
		top: 0px
	}
	
	.edit-app-sku-item-unit-addon{
		position: absolute;
		right: 0px;
	}


	.edit-app-content-text {
		width: 120px;
		height: 40px;
		border-radius: 4px;
		border: 1px solid #5333FF;
	}

	.mobile-content {
		width: 750px;
	}

	.mobile-item {
		position: relative;
	}

	.mobile-item .action {
		position: absolute;
		left: 0;
		top: -15px;
		width: 100%;
		z-index: 2;
	}

	.del-action {
		width: 30px;
		height: 30px;
		background: rgba(0, 0, 0, .5);
		text-align: center;
		line-height: 30px;
		border-radius: 15px;
		color: #FFFFFF;
		position: absolute;
		right: -15px;
		top: -15px;
		z-index: 3;
	}

	.up-action,
	.down-action {
		width: 30px;
		height: 30px;
		background: #FFFFFF;
		text-align: center;
		line-height: 30px;
		border-radius: 15px;
		color: #007AFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);

	}
</style>
