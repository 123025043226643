<template>
	<div>
		<a-modal title="选择商户" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定选择
				</a-button>
			</template>
			<div>
				<div>
					 <a-input-search v-model="search.keyword" placeholder="搜索商户帐号" style="width: 480px" @search="onSearch" />
				</div>
				<div class="mt20">
					<div class="wxb-table-gray">
						<a-table rowKey="account_id" :row-selection="{ selectedRowKeys: selectedRowKeys,onChange: onSelectChange}" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
						 :loading="loading">
							
						</a-table>
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	export default {
		mixins:[listMixin],
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			value:{
				type:Array,
				default:function(){
					return new Array
				},
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				search:{
					keyword:'',
				},
				pagination:{
					current:1,
					pageSize:5,
				},
				selectedRowKeys:[],
				columns: [
					{title: '帐号名称',dataIndex: 'username',align: 'center',ellipsis: true},
					{title: '手机号',dataIndex: 'mobile',align: 'center',ellipsis: true},
				],
				datas: [],
			}
		},
		created() {
			this.selectedRowKeys=this.value;
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('platform/admin/getAccountList',{
					status:1,
					keyword:this.search.keyword,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
				}).catch(res=>{
					console.log(res);
				}).finally(()=>{
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			
			onSelectChange(selectedRowKeys,selectedRows) {
			    this.selectedRowKeys = selectedRowKeys;
				console.log(selectedRows);
			},
			
			onSearch(){
				this.getLists();
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$emit('input',this.selectedRowKeys);
				this.confirmLoading=false;
				this.$emit("ok");
			},
			
		}
	}
</script>

<style>
	.choose-merchant-table-logo img{
		width: 44px;
		height: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
</style>
